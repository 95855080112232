.navbar {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;
    max-width: 1350px;
    margin: 0 auto;
    font-family: 'Montserrat';
    box-sizing: border-box;
    position: relative;
    gap:10px;
}

.login-con {
    display: flex;
    align-items: center;
    gap: 15px;
}
.loggedin-con {
    display: flex;
    align-items: center;
    gap: 15px;
}


.menu-box li{
    font-size: 16px;
    font-weight: 500;
}

.menu-box{
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
}