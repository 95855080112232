.Story {
  width: 100%;
  max-width: 1350px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  gap: 100px;
}

.writer-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.writer-info-left {
  display: flex;
  align-items: center;
}

.writer-info-mid {
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
}

.writer-info-mid .lower {
  display: flex;
  gap: 20px;
}

.writer-info-right {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  gap: 20px;
}

.likes {
  display: flex;
  gap: 5px;
}

.likes img:hover {
  cursor: pointer;
}


.story-content-title {
  font-size: 36px;
  font-weight: 600;
  padding-top: 20px;
}

.story-content-read {
  font-size: 20px;
  font-weight: 500;
}

.action-con {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-con img:hover {
  cursor: pointer;
}

.action-con img {
  width: 20px;
}

.advert {
  width: 100%;
  height: 475px;
  background: #F2E2FF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 100px 20px 100px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  position: sticky;
  top: 20px;
}

.advert p {
  font-size: 20px;
  font-weight: 600;
  font-size: 32px;
  line-height: 122.52%;
}

.advert-snake1{
  position: absolute;
  right: 0px;
  top: 10px;
}

.advert-snake2{
  position: absolute;
  bottom: 0px;
  right: 0px;
}