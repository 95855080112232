.Card{
    width: 100%;
    padding-bottom: 15px;
    padding-top: 20px;
    border-bottom: 1px solid #46464625;
}

.user-info{
    display: flex;
    gap: 10px;
    font-size:18px;
    font-weight:500;
    align-items:center;
    color:#464646;
}

.card-title{
    font-size: 24px;
    font-weight: 600;
    padding-left: 10px;
    margin-right: 15px;
}
.card-title:hover{
    cursor: pointer;
}

.card-brief{
    font-size: 18px;
    font-weight: 400;
    color:#464646;
    line-height: 25px;
    padding-left: 10px;
    padding-right: 20px;
}

.card-stats{
    padding-top: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap:5px;
    font-weight: 500;
    justify-content: space-between;
}

.card-stats img{
    width:20px;
}

.bin{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -48px;
}

.bin img:hover{
    cursor: pointer;
}

#bin{
    background-color: white !important;
}
