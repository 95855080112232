.Write {
  width: 100%;
  max-width: 1350px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px auto;
}

.title {
  margin-top: 50px;
  max-width: 1350px;
}

.medium-editor-element {
  outline: none;
  background-color: #F9F0FF;
  padding: 20px;
}

.content .medium-editor-element {
  font-size: 20px;
  height: 100%;
  min-height: 60vh;
  border-radius: 0 0 10px 10px;
}

.content {
  height: 100%;
  min-height: 60vh;
}

.publish {
  margin-top: 20px;
  margin-bottom: 20px;
}

.content .medium-editor-placeholder {
  font-size: 20px;
}


.title input {
  outline: none;
  font-size: 40px;
  background-color: #F9F0FF;
  border-radius: 8px 8px 0 0;
  border: none;
  padding: 20px;
  width: 100%;
  font-weight: 600;
  border-bottom: 2px solid rgba(161, 161, 161, 0.144);
  border-left: 5px solid #7E47FF;

}

.title input::placeholder {
  font-weight: 600;
}

#topics-con {
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
  background-color: rgb(228, 228, 228);
  padding: 20px;
  border-radius: 10px;
}

#topics-con .topic-header {
  font-weight: 600;
  font-size: 20px;
}