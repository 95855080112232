@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-bottom: 0px;
}

::selection {
  color: white;
  background: #7E47FF;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;

}

::-webkit-scrollbar-track {
  background-color: rgb(224, 224, 224);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(184deg, #7E47FF 0%, #b7a8d8);
  border-radius: 10px;
}

button{
  font-size: 16px;
  color: white;
  background-color: #7E47FF;
  border-radius: 8px;
  width: auto;
  height: 38px;
  outline: none;
  border: 0px;
  font-weight: 600;
  transition: 0.2s;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.3s;
}

button:active{
transform: scale(0.95);
}

button:hover{
  cursor: pointer;
  background-color: #6A3BFF;
}