.Home {
    width: 100%;
    max-width: 1350px;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    margin: 0px auto;
    gap: 100px;
}


.left-con {
    flex: 0.7;
    max-height: 88vh;
    /* overflow: hidden; */
}

.left-con::-webkit-scrollbar {
    display: none;
}

.ant-tabs-tabpane{
    width: 100%;
    max-height: 82vh;
    overflow: scroll;
}

.ant-tabs-tabpane::-webkit-scrollbar {
    display: none;
}

.right-con {
    flex: 0.3;
}

.ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 20px;
}

.ant-tabs-ink-bar {
    background: #7E47FF !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
    color: #7E47FF !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #7E47FF !important;
}

.ant-tabs-tab-btn:active{
    color: #7E47FF !important;
}

.ant-tabs-tab:hover {
    color: #7E47FF !important;
    font-weight: 500;
    font-size: 20px;
}


/* .ant-tabs-tab {
    color:#7E47FF;
} */

.search-con{
    background: #F0F5F5;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 100%;
    padding:12px;
    font-weight: 600;
    display:flex;
    align-items: center;
    gap:10px;
}
.search-con input{
    background: #F0F5F5;
    width: 100%;
    outline: none;
    border: none;
    font-size: 15px;
    font-weight: 600;
}
.search-con input:focus{
   outline: none;
}

.topics-con{
    padding-top: 40px;
    width: 100%;
}

.topics-con .topics{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding-bottom: 10px;
}

.topics-con .topic{
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 10px;
    font-weight: 500;
}

#highlight{
    background-color: #7E47FF !important;
    color: white;
}

.topic{
    margin: 4px;
}

.topic:hover{
    background-color: #DCCDFF ;
    cursor:pointer;
}

.topic-head{
    font-weight: 600;
    font-size: 20px;
}