.Follow{
    padding-top: 20px;
    width: 100%;
}

.Follow p{
  font-weight: 600;
  font-size: 20px;
}

.follow-user{
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.follow-user .side{
    display: flex;
    align-items: center;
    gap: 15px;
}

.follow-user p{
    font-size: 18px;
    font-weight: 500;
}