.Landing {
    max-width: 1350px;
    margin: 0 auto;
    font-family: 'Montserrat';
    box-sizing: border-box;
    position: relative;
}

/* Navbar styling */

.navbar {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;
}

.navbar img:hover {
    cursor: pointer;
}

.login-con {
    display: flex;
    align-items: center;
    gap: 10px;
}

.Landing button {
    font-size: 16px;
    color: white;
    background-color: #7E47FF;
    border-radius: 8px;
    width: auto;
    height: 38px;
    outline: none;
    border: 0px;
    font-weight: 600;
    transition: 0.2s;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.3s;
}

.Landing button:active {
    transform: scale(0.95);
}

.Landing button:hover {
    cursor: pointer;
    background-color: #6A3BFF;
}

#login-btn {
    background-color: transparent;
    color: black;
}

/* Hero styling */

.hero {
    height: 80vh;
    padding: 60px 0px;
    display: flex;
    align-items: center;
    position: relative;
}

.hero-gradient {
    position: absolute;
    left: -600px;
    top: -100px;
    z-index: -999;
}

.herocon-left p {
    font-weight: 600;
    color: #464646;
    font-style: normal;
    font-size: 24px;
    line-height: 122.52%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.herocon-left button {
    height: 46px;
}

.herocon-left img {
    width: 100%;
}

.herocon-right img {
    width: 100%;
}

/* Zigzag styling */
#one {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

/* Feature styling */

.features {
    width: 100%;
    margin-top: 80px;
    position: relative;
}

#two {
    position: absolute;
    top: 2400px;
    bottom: 50px;
    right: 0px;
}

#three {
    position: absolute;
    top: 480px;
    left: -600px;
    transform: rotate(20deg);
    z-index: -1;
}


.featurehead-con {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;
}

.featurehead {
    font-size: 64px;
    font-weight: 600;
}

.featuresmall {
    font-size: 24px;
    font-weight: 600;
    color: #464646;
}

.featuresmall span a {
    color: #6A3BFF !important;
}

.feature-card {
    width: 100%;
    max-width: 390px;
    height: 433px;
    background: rgba(248, 248, 250, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.8);
    box-shadow: 2px 1px 29px rgba(126, 71, 255, 0.261);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    padding-top: 50px;
    box-sizing: border-box;
    z-index: 999;
}

.feature-card img {
    padding: 20px;
}

.featurecard-con {
    padding: 0px 0px 100px 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.featurecard-head {
    font-weight: 600;
    font-size: 32px;
}

.featurecard-small {
    font-weight: 500;
    font-size: 20px;
    color: #464646;
    text-align: center;
}

/* Minting styling */
.minting,
.economy {
    padding-top: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.minting-left,
.economy-right {
    max-width: 50%;
}

.minting span,
.economy span {
    color: #7E47FF;
}

.economy h1,
.minting h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
}

.economy img,
.minting img {
    width: 350px;
}

.economy p,
.minting p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #464646;
}

/* FAQ styling */
.faq {
    margin-top: 80px;
}

.collapse-con {
    max-width: 1000px;
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 100px;
}

.panel {
    background-color: #F8F8FA;
    font-weight: 500;
    box-shadow: none;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    margin-bottom: 15px;
}

.collapse {
    border: none;
    background-color: transparent;
}

.grid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

/* Footer styling */

.footer {
    max-width: 1350px;
    color: #464646;
    padding: 50px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.socials {
    display: flex;
    align-items: center;
    gap: 10px;
}

.socials img {
    width: 30px;
    height: 30px;
    transition: 0.2s;
}



/* CSS Media Query */

/* media for small laptop */

@media screen and (min-width: 971px) and (max-width: 1380px) {
    .Landing {
        /* padding: 10px; */
        overflow-x: hidden;
        overflow-y: hidden;
        padding: 20px;
        margin: 0;
    }

    .Landing .feature-card {
        width: 100%;
        max-width: 380px;
        height: 433px;
        padding: 20px;
        padding-top: 50px;
        box-sizing: border-box;
        z-index: 9999;
    }

    .Landing .feature-card img {
        padding: 20px;
    }

    .Landing .featurecard-con {
        padding: 0px 0px 100px 0px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .Landing .featurecard-head {
        font-size: 30px;
    }

    .Landing .featurecard-small {
        font-size: 18px;
    }
}

/* media query for tablet view  */

@media screen and (max-width: 1182px) {
    .Landing {
        margin: 10px;
    }

    .Landing .featurecard-con {
        flex-direction: column;
        align-items: center;
    }
}

/* media for mobile view  */

@media screen and (max-width: 800px) {
    .Landing {
        overflow-x: hidden;
        overflow-y: hidden;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
    }

    .hero {
        height: 80vh;
    }

    .hero .herocon-right img {
        display: none;
    }

    .economy {
        text-align: center;
        flex-direction: column;
        align-items: center;
    }

    .minting {
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-wrap: wrap-reverse;
        gap: 20px;
        padding-top: 50px;
    }

    .economy {
        gap: 20px;
    }

    .minting img,
    .economy img {
        width: 250px;
    }

    .minting h1,
    .economy h1 {
        font-size: 32px;
        width: 100%;
    }

    .minting p,
    .economy p {
        font-size: 20px;
    }

    .minting-left,
    .economy-right {
        max-width: 82%;
    }

    #two, #three {
        display: none;
    }

    .featurecard-con {
        gap: 10px;
    }

    .featuresmall {
        text-align: center;
    }
}