.Register p {
    margin-bottom: 0px;
}

.Register .nav {
    max-width: 1350px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 20px 0;
}

.Register .nav img {
    width: 60px;
    height: 60px;
    transition: 0.2s;
}

.Register .nav img:hover {
    cursor: pointer;
    transform: scale(1.2);
}


.auth-con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0px;
}

.auth-card {
    width: 500px;
    background: #FFFFFF;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.2);
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
    gap: 10px;
}

.authcard-head {
    font-family: 'Montserrat';
    font-size: 35px;
    font-weight: 600;

}

.authcard-small {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
}

.auth-card img {
    margin-bottom: 50px;
}

.auth-card input {
    width: 100%;
    outline: none;
    border-radius: 6px;
    padding: 5px;
    border: 2px solid rgba(111, 111, 111, 0.758);
    font-family: 'Montserrat';
    font-size: 16px;
    height: 50px;
    font-weight: 500;
}

.auth-card input:focus {
  border: 1px solid rgb(133, 59, 160);
  filter: drop-shadow(0px 0px 2px #8f30edad);
  outline: none;
}

.auth-card button {
    color: white;
    width: 100%;
    height: 60px;
    background-color: #7E47FF;
    border: none;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
}

.auth-card button:hover {
   cursor: pointer;
   background-color: #6A3BFF;
}

.no-acc{
  display: flex;
  width:100%;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
}

.no-acc span{
  color: #7E47FF !important;
  font-weight: 600;
}
.no-acc span:hover{
  cursor: pointer;
}

.loader{
    width: 85px;
    margin-bottom: 0px !important;
}



/* Footer styling */

.footer {
    margin: 0 auto;
    max-width: 1350px;
    color: #464646;
    padding: 50px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.socials {
    display: flex;
    align-items: center;
    gap: 10px;
}

.socials img {
    width: 30px;
    height: 30px;
    transition: 0.2s;
}

@media screen and (max-width: 800px) {
    .Register  {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-x: hidden;
    }
    .bubbles{
        display: none;
    }
    .Register .auth-con{
        padding: 0px;
    }
    .Register .auth-card {
        box-sizing: border-box;
        width: 100%;
        padding: 20px;
        box-shadow:none;
    }
    .Register .footer {
        padding: 25px 5px;
    }

}